import { useState } from "react";
import RenderLogoWithText from "./RenderLogoWithText";
import "../css/popup.css";

function Popup(props) {
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const toggleVisibility = () => {
    localStorage.setItem("welcome-modal", false);
    setIsPopupVisible(false);
  };
  if (!isPopupVisible) return null;

  return (
    <div className="popcnt manrope">
      <div className="pupup">
        <RenderLogoWithText className="lkhg" />
        <div className="ill" data-aos="fade-up">
          <svg
            width="222"
            height="219"
            viewBox="0 0 222 219"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_87_2308)">
              <path d="M187.008 0H76.9717V110.061H187.008V0Z" fill="#F4F4F4" />
              <path d="M188.269 0H187.008V110.061H188.269V0Z" fill="#F4F4F4" />
              <path
                opacity="0.05"
                d="M188.269 0H187.008V110.061H188.269V0Z"
                fill="#292A2E"
              />
              <path
                opacity="0.05"
                d="M160.351 75.8406V47.0449H136.769V63.7746H100.239V93.3965H84.7139V110.06H100.239H103.23H181.715V75.8406H160.351Z"
                fill="#292A2E"
              />
              <path
                opacity="0.34"
                d="M156.969 50.1719H139.796V53.2984H156.969V50.1719Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M156.969 56.6504H139.796V59.7769H156.969V56.6504Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M156.969 63.1289H139.796V66.2554H156.969V63.1289Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M178.669 78.875H175.504V82.0015H178.669V78.875Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M178.669 85.3535H175.504V88.48H178.669V85.3535Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M178.669 91.832H175.504V94.9586H178.669V91.832Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M172.814 78.875H169.648V82.0015H172.814V78.875Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M172.814 85.3535H169.648V88.48H172.814V85.3535Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M172.814 91.832H169.648V94.9586H172.814V91.832Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M167.116 78.875H163.951V82.0015H167.116V78.875Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M167.116 85.3535H163.951V88.48H167.116V85.3535Z"
                fill="#FFFFFA"
              />
              <path
                opacity="0.34"
                d="M167.116 91.832H163.951V94.9586H167.116V91.832Z"
                fill="#FFFFFA"
              />
              <path
                d="M187.008 54.413H164.867V0H163.036V54.413H103.23V0H101.399V54.413H76.9717V56.2445H101.399V110.061H103.23V56.2445H163.036V110.061H164.867V56.2445H187.008V54.413Z"
                fill="white"
              />
              <path
                d="M191.391 109.928H72.5879V113.588H191.391V109.928Z"
                fill="#F4F4F4"
              />
              <path
                opacity="0.1"
                d="M191.391 109.928H72.5879V113.588H191.391V109.928Z"
                fill="#292A2E"
              />
              <path
                opacity="0.1"
                d="M193.107 109.928H191.392V113.588H193.107V109.928Z"
                fill="#292A2E"
              />
              <path
                opacity="0.1"
                d="M193.107 109.928H191.392V113.588H193.107V109.928Z"
                fill="#292A2E"
              />
              <path
                d="M188.269 1.96484H76.9717V4.6863H188.269V1.96484Z"
                fill="white"
              />
              <path
                d="M188.269 7.04883H76.9717V9.77028H188.269V7.04883Z"
                fill="white"
              />
              <path
                d="M188.269 12.1309H76.9717V14.8523H188.269V12.1309Z"
                fill="white"
              />
              <path
                d="M188.269 17.2129H76.9717V19.9343H188.269V17.2129Z"
                fill="white"
              />
              <path
                d="M188.269 22.2969H76.9717V25.0183H188.269V22.2969Z"
                fill="white"
              />
              <path d="M176.448 0H174.78V28.0872H176.448V0Z" fill="white" />
              <path
                d="M175.755 32.1211C177.021 32.1211 178.047 31.0949 178.047 29.8291C178.047 28.5633 177.021 27.5371 175.755 27.5371C174.49 27.5371 173.464 28.5633 173.464 29.8291C173.464 31.0949 174.49 32.1211 175.755 32.1211Z"
                fill="white"
              />
              <path
                d="M218.787 219H0.17785C0.154494 219 0.131368 218.996 0.10979 218.987C0.088212 218.978 0.068606 218.965 0.0520911 218.948C0.0355762 218.932 0.0224758 218.912 0.013538 218.891C0.00460023 218.869 0 218.846 0 218.822C0 218.799 0.00460023 218.776 0.013538 218.754C0.0224758 218.733 0.0355762 218.713 0.0520911 218.697C0.068606 218.68 0.088212 218.667 0.10979 218.658C0.131368 218.649 0.154494 218.645 0.17785 218.645H218.786C218.833 218.645 218.878 218.663 218.912 218.697C218.945 218.73 218.964 218.775 218.964 218.822C218.964 218.87 218.945 218.915 218.912 218.948C218.878 218.982 218.833 219 218.786 219H218.787Z"
                fill="#292A2E"
              />
              <g opacity="0.65">
                <path
                  d="M218.787 219H0.17785C0.154494 219 0.131368 218.996 0.10979 218.987C0.088212 218.978 0.068606 218.965 0.0520911 218.948C0.0355762 218.932 0.0224758 218.912 0.013538 218.891C0.00460023 218.869 0 218.846 0 218.822C0 218.799 0.00460023 218.776 0.013538 218.754C0.0224758 218.733 0.0355762 218.713 0.0520911 218.697C0.068606 218.68 0.088212 218.667 0.10979 218.658C0.131368 218.649 0.154494 218.645 0.17785 218.645H218.786C218.833 218.645 218.878 218.663 218.912 218.697C218.945 218.73 218.964 218.775 218.964 218.822C218.964 218.87 218.945 218.915 218.912 218.948C218.878 218.982 218.833 219 218.786 219H218.787Z"
                  fill="#292A2E"
                />
              </g>
              <path
                d="M15.3974 218.687L14.8984 218.591L22.6974 141.074L23.1964 141.17L15.3974 218.687Z"
                fill="#494949"
              />
              <path
                d="M44.4375 218.687L43.9385 218.591L51.7375 141.074L52.2362 141.17L44.4375 218.687Z"
                fill="#494949"
              />
              <path
                d="M108.629 218.687L109.128 218.591L101.329 141.074L100.83 141.17L108.629 218.687Z"
                fill="#494949"
              />
              <path
                d="M79.589 218.687L80.0878 218.591L72.289 141.074L71.79 141.17L79.589 218.687Z"
                fill="#494949"
              />
              <path
                d="M69.2459 106.96L68.7422 107.027L73.2982 141.158L73.8019 141.09L69.2459 106.96Z"
                fill="#494949"
              />
              <path
                d="M16.9519 106.958L16.4482 107.025L21.0042 141.156L21.5079 141.089L16.9519 106.958Z"
                fill="#494949"
              />
              <path
                d="M73.7578 138.623H22.6082C21.2281 138.623 20.1094 139.742 20.1094 141.122V141.123C20.1094 142.503 21.2281 143.622 22.6082 143.622H73.7578C75.1379 143.622 76.2566 142.503 76.2566 141.123V141.122C76.2566 139.742 75.1379 138.623 73.7578 138.623Z"
                fill="#292A2E"
              />
              <path
                opacity="0.8"
                d="M73.7578 138.623H22.6082C21.2281 138.623 20.1094 139.742 20.1094 141.122V141.123C20.1094 142.503 21.2281 143.622 22.6082 143.622H73.7578C75.1379 143.622 76.2566 142.503 76.2566 141.123V141.122C76.2566 139.742 75.1379 138.623 73.7578 138.623Z"
                fill="white"
              />
              <path
                d="M73.7571 143.876H22.6085C21.8829 143.869 21.1895 143.576 20.6789 143.061C20.1683 142.545 19.8818 141.848 19.8818 141.123C19.8818 140.397 20.1683 139.701 20.6789 139.185C21.1895 138.669 21.8829 138.376 22.6085 138.369H73.7571C74.4827 138.376 75.1762 138.669 75.6868 139.185C76.1974 139.701 76.4838 140.397 76.4838 141.123C76.4838 141.848 76.1974 142.545 75.6868 143.061C75.1762 143.576 74.4827 143.869 73.7571 143.876ZM22.6085 138.877C22.0173 138.884 21.4524 139.123 21.0365 139.543C20.6207 139.964 20.3874 140.531 20.3874 141.123C20.3874 141.714 20.6207 142.282 21.0365 142.702C21.4524 143.122 22.0173 143.362 22.6085 143.368H73.7571C74.3484 143.362 74.9133 143.122 75.3291 142.702C75.745 142.282 75.9782 141.714 75.9782 141.123C75.9782 140.531 75.745 139.964 75.3291 139.543C74.9133 139.123 74.3484 138.884 73.7571 138.877H22.6085Z"
                fill="#292A2E"
              />
              <path
                d="M101.831 138.623H50.6814C49.3014 138.623 48.1826 139.742 48.1826 141.122V141.123C48.1826 142.503 49.3014 143.622 50.6814 143.622H101.831C103.211 143.622 104.33 142.503 104.33 141.123V141.122C104.33 139.742 103.211 138.623 101.831 138.623Z"
                fill="white"
              />
              <path
                d="M101.832 143.876H50.6819C50.3182 143.88 49.9573 143.811 49.6202 143.675C49.2832 143.538 48.9765 143.335 48.7181 143.079C48.4596 142.823 48.2545 142.519 48.1145 142.183C47.9744 141.847 47.9023 141.487 47.9023 141.123C47.9023 140.759 47.9744 140.399 48.1145 140.063C48.2545 139.727 48.4596 139.422 48.7181 139.166C48.9765 138.91 49.2832 138.708 49.6202 138.571C49.9573 138.434 50.3182 138.366 50.6819 138.369H101.832C102.195 138.366 102.556 138.434 102.893 138.571C103.23 138.708 103.537 138.91 103.795 139.166C104.054 139.422 104.259 139.727 104.399 140.063C104.539 140.399 104.611 140.759 104.611 141.123C104.611 141.487 104.539 141.847 104.399 142.183C104.259 142.519 104.054 142.823 103.795 143.079C103.537 143.335 103.23 143.538 102.893 143.675C102.556 143.811 102.195 143.88 101.832 143.876ZM50.6819 138.878C50.3851 138.874 50.0906 138.93 49.8155 139.042C49.5404 139.153 49.2901 139.318 49.0791 139.527C48.8681 139.735 48.7006 139.984 48.5862 140.258C48.4719 140.532 48.4131 140.826 48.4131 141.123C48.4131 141.42 48.4719 141.714 48.5862 141.988C48.7006 142.262 48.8681 142.51 49.0791 142.719C49.2901 142.928 49.5404 143.093 49.8155 143.204C50.0906 143.316 50.3851 143.371 50.6819 143.368H101.832C102.128 143.371 102.423 143.316 102.698 143.204C102.973 143.093 103.223 142.928 103.434 142.719C103.645 142.51 103.813 142.262 103.927 141.988C104.042 141.714 104.1 141.42 104.1 141.123C104.1 140.826 104.042 140.532 103.927 140.258C103.813 139.984 103.645 139.735 103.434 139.527C103.223 139.318 102.973 139.153 102.698 139.042C102.423 138.93 102.128 138.874 101.832 138.878H50.6819Z"
                fill="#292A2E"
              />
              <path
                d="M66.4434 106.993H16.8367C16.0257 106.993 15.2424 106.697 14.633 106.162C14.0236 105.627 13.6298 104.888 13.5251 104.084L10.2438 78.8792C10.1824 78.4081 10.2223 77.9292 10.3606 77.4747C10.499 77.0202 10.7327 76.6005 11.0461 76.2435C11.3596 75.8865 11.7456 75.6005 12.1783 75.4046C12.6111 75.2087 13.0806 75.1074 13.5556 75.1074H63.1621C63.9731 75.1074 64.7564 75.4026 65.3657 75.9379C65.9751 76.4732 66.3689 77.212 66.4736 78.0164L69.755 103.221C69.8163 103.692 69.7764 104.171 69.6381 104.625C69.4997 105.08 69.266 105.499 68.9526 105.856C68.6392 106.213 68.2533 106.499 67.8206 106.695C67.3879 106.891 66.9184 106.993 66.4434 106.993Z"
                fill="#292A2E"
              />
              <path
                opacity="0.8"
                d="M66.4434 106.993H16.8367C16.0257 106.993 15.2424 106.697 14.633 106.162C14.0236 105.627 13.6298 104.888 13.5251 104.084L10.2438 78.8792C10.1824 78.4081 10.2223 77.9292 10.3606 77.4747C10.499 77.0202 10.7327 76.6005 11.0461 76.2435C11.3596 75.8865 11.7456 75.6005 12.1783 75.4046C12.6111 75.2087 13.0806 75.1074 13.5556 75.1074H63.1621C63.9731 75.1074 64.7564 75.4026 65.3657 75.9379C65.9751 76.4732 66.3689 77.212 66.4736 78.0164L69.755 103.221C69.8163 103.692 69.7764 104.171 69.6381 104.625C69.4997 105.08 69.266 105.499 68.9526 105.856C68.6392 106.213 68.2533 106.499 67.8206 106.695C67.3879 106.891 66.9184 106.993 66.4434 106.993Z"
                fill="white"
              />
              <path
                d="M66.4436 107.246H16.837C15.9649 107.244 15.1231 106.926 14.4677 106.351C13.8124 105.775 13.388 104.981 13.2734 104.117L9.99202 78.9122C9.926 78.4052 9.96886 77.89 10.1177 77.4009C10.2666 76.9119 10.5181 76.4602 10.8554 76.0761C11.1926 75.6919 11.608 75.3842 12.0736 75.1734C12.5393 74.9625 13.0445 74.8535 13.5556 74.8535H63.1623C64.0346 74.8555 64.8765 75.1739 65.5319 75.7496C66.1872 76.3253 66.6116 77.1192 66.7262 77.9841L70.0075 103.188C70.0734 103.695 70.0305 104.21 69.8816 104.699C69.7327 105.188 69.4812 105.64 69.1439 106.024C68.8066 106.408 68.3913 106.716 67.9256 106.927C67.46 107.138 66.9548 107.247 66.4436 107.247V107.246ZM13.5556 75.3613C13.1168 75.3613 12.683 75.4549 12.2832 75.6359C11.8834 75.817 11.5268 76.0812 11.2373 76.411C10.9477 76.7409 10.7318 77.1287 10.604 77.5486C10.4762 77.9685 10.4394 78.4109 10.4961 78.8461L13.7772 104.05C13.8756 104.793 14.24 105.475 14.8027 105.969C15.3653 106.463 16.0881 106.736 16.837 106.738H66.4436C66.8826 106.738 67.3164 106.644 67.7163 106.463C68.1162 106.282 68.4728 106.018 68.7624 105.688C69.052 105.358 69.2679 104.97 69.3957 104.55C69.5235 104.13 69.5602 103.688 69.5034 103.253L66.2221 78.0484C66.1236 77.3059 65.7593 76.6244 65.1966 76.1303C64.6339 75.6361 63.9111 75.3629 63.1623 75.3613H13.5556Z"
                fill="#292A2E"
              />
              <path
                d="M69.2492 106.993H19.6426C18.8315 106.993 18.0482 106.697 17.4388 106.162C16.8293 105.627 16.4355 104.888 16.3307 104.084L13.0494 78.8792C12.9881 78.4081 13.028 77.9293 13.1664 77.4748C13.3048 77.0203 13.5385 76.6006 13.8519 76.2437C14.1654 75.8867 14.5513 75.6007 14.984 75.4048C15.4167 75.2088 15.8862 75.1075 16.3612 75.1074H65.9679C66.7789 75.1074 67.5623 75.4026 68.1717 75.9379C68.7811 76.4732 69.175 77.212 69.2797 78.0164L72.5611 103.221C72.6223 103.692 72.5824 104.171 72.4441 104.625C72.3057 105.08 72.072 105.499 71.7585 105.856C71.4451 106.213 71.0591 106.499 70.6264 106.695C70.1937 106.891 69.7242 106.993 69.2492 106.993Z"
                fill="white"
              />
              <path
                d="M69.2493 107.246H19.6426C18.7705 107.244 17.9287 106.926 17.2733 106.351C16.618 105.775 16.1937 104.981 16.079 104.117L12.7977 78.9122C12.7317 78.4052 12.7745 77.89 12.9234 77.4009C13.0723 76.9119 13.3237 76.4602 13.661 76.0761C13.9983 75.6919 14.4136 75.3842 14.8793 75.1734C15.3449 74.9625 15.8502 74.8535 16.3613 74.8535H65.968C66.8402 74.8554 67.6821 75.1738 68.3375 75.7495C68.9928 76.3253 69.4171 77.1192 69.5316 77.9841L72.8129 103.188C72.8789 103.695 72.8361 104.21 72.6872 104.699C72.5383 105.189 72.2868 105.64 71.9496 106.024C71.6123 106.408 71.197 106.716 70.7313 106.927C70.2657 107.138 69.7604 107.247 69.2493 107.247V107.246ZM16.3613 75.3613C15.9224 75.3613 15.4886 75.4549 15.0888 75.6359C14.689 75.817 14.3324 76.0812 14.0429 76.411C13.7533 76.7408 13.5373 77.1287 13.4095 77.5486C13.2817 77.9685 13.2448 78.4108 13.3015 78.8461L16.5828 104.05C16.6812 104.793 17.0455 105.475 17.6082 105.969C18.1709 106.463 18.8938 106.736 19.6426 106.738H69.2493C69.6883 106.738 70.1222 106.645 70.522 106.463C70.9219 106.282 71.2786 106.018 71.5682 105.688C71.8579 105.358 72.0738 104.97 72.2016 104.55C72.3294 104.13 72.3661 103.688 72.3093 103.253L69.028 78.0484C68.9297 77.3058 68.5653 76.6242 68.0027 76.1299C67.44 75.6356 66.7171 75.3622 65.9682 75.3605L16.3613 75.3613Z"
                fill="#292A2E"
              />
              <path
                d="M139.212 205.723C139.212 205.723 140.574 212.786 147.186 213.179C153.797 213.573 152.364 218.841 152.364 218.841H127.098V206.215L139.212 205.723Z"
                fill="#DA2F72"
              />
              <path
                d="M91.1438 201.263C91.1438 201.263 89.2492 208.202 95.0083 211.475C100.767 214.747 97.1557 218.841 97.1557 218.841L74.4844 207.685L80.0564 196.355L91.1438 201.263Z"
                fill="#DA2F72"
              />
              <path
                d="M70.7998 36.6823C70.7998 36.6823 74.0961 34.1936 74.9129 28.465C75.5453 24.0295 71.4548 26.9029 71.4548 26.9029C71.4548 26.9029 71.8791 23.705 69.5492 24.0221C67.2194 24.3393 65.916 27.6993 65.916 27.6993"
                fill="#0C0C0C"
              />
              <path
                d="M57.5098 24.3965C57.5098 24.3965 45.9508 25.6361 50.1292 41.3026L51.1521 41.3709L53.2284 41.5102"
                fill="#0C0C0C"
              />
              <path
                d="M68.864 57.6631C68.8132 57.6565 68.2491 67.015 57.3784 66.1998C49.8528 65.6361 47.1696 57.6499 46.9971 57.6499C47.8086 57.4773 48.8111 57.2156 49.2098 57.1256C50.0783 56.924 50.8648 56.4626 51.4647 55.8029C52.0646 55.1432 52.4494 54.3165 52.5681 53.4327C52.7884 52.1061 52.4156 45.3189 52.5297 43.9766L64.0816 46.5557C63.9203 49.3905 62.7904 55.9625 66.7493 57.1256C67.4092 57.318 68.1188 57.4949 68.864 57.6631Z"
                fill="#E98862"
              />
              <path
                opacity="0.52"
                d="M64.2438 54.4398C64.0629 53.8566 63.9463 53.2553 63.8962 52.6467C63.7694 51.2577 63.795 49.8591 63.9724 48.4757C61.6245 48.1587 59.4125 47.1893 57.5879 45.6777C57.3734 46.4701 56.1938 49.9323 56.5208 50.6856C57.1369 52.1089 60.0376 54.4398 64.2438 54.4398Z"
                fill="#0C0C0C"
              />
              <path
                d="M61.8595 52.5885C57.302 52.1796 51.8474 49.2996 51.7688 43.1118L51.6977 37.5289C51.6192 31.3411 54.8045 26.2838 60.6293 26.2098C66.4539 26.1356 70.8715 31.0964 70.95 37.2839L70.9815 39.7556C70.9774 40.6044 70.9155 41.4521 70.7962 42.2925C70.4975 44.5161 70.034 47.8968 68.5256 50.1839C67.1008 52.3428 64.7709 52.8497 61.8595 52.5885Z"
                fill="#E98862"
              />
              <path
                d="M63.9739 46.9326C64.9144 46.9752 65.8517 46.7962 66.7103 46.4098C66.728 46.4023 66.7441 46.3913 66.7576 46.3775C66.7711 46.3637 66.7818 46.3474 66.789 46.3294C66.7962 46.3115 66.7998 46.2924 66.7996 46.2731C66.7994 46.2538 66.7954 46.2347 66.7878 46.217C66.7802 46.1992 66.7692 46.1831 66.7554 46.1696C66.7416 46.1561 66.7253 46.1455 66.7074 46.1383C66.6895 46.1311 66.6704 46.1275 66.6511 46.1277C66.6318 46.1279 66.6127 46.1319 66.5949 46.1394C64.7453 46.9285 63.0656 46.8129 61.7379 45.8053C61.711 45.7841 61.6831 45.7643 61.6543 45.7461C61.6209 45.7306 61.5829 45.7282 61.5479 45.7393C61.5128 45.7505 61.4832 45.7743 61.4649 45.8062C61.4466 45.8381 61.4408 45.8757 61.4488 45.9116C61.4569 45.9475 61.478 45.9791 61.5082 46.0002C61.5188 46.0068 61.5359 46.0203 61.559 46.0388C62.257 46.5706 63.0979 46.8818 63.9739 46.9326Z"
                fill="#0C0C0C"
              />
              <path
                d="M63.9297 48.3266C64.0996 48.3372 64.2701 48.3331 64.4394 48.3144C64.4781 48.3103 64.5136 48.291 64.5381 48.2606C64.5626 48.2303 64.5741 48.1915 64.57 48.1528C64.5658 48.114 64.5465 48.0785 64.5162 48.054C64.4859 48.0295 64.4471 48.018 64.4084 48.0222C63.3903 48.1304 62.9045 47.6638 62.8839 47.6438C62.8561 47.6174 62.8191 47.603 62.7809 47.6035C62.7426 47.604 62.706 47.6195 62.6789 47.6465C62.6518 47.6736 62.6363 47.7102 62.6358 47.7485C62.6352 47.7868 62.6496 47.8238 62.6759 47.8516C63.0265 48.1499 63.4695 48.3178 63.9297 48.3266Z"
                fill="#0C0C0C"
              />
              <path
                d="M69.0271 40.9451C69.4233 40.9451 69.7446 40.6238 69.7446 40.2274C69.7446 39.8311 69.4233 39.5098 69.0271 39.5098C68.6308 39.5098 68.3096 39.8311 68.3096 40.2274C68.3096 40.6238 68.6308 40.9451 69.0271 40.9451Z"
                fill="#0C0C0C"
              />
              <path
                d="M62.0173 40.5056C62.4136 40.5056 62.7348 40.1843 62.7348 39.788C62.7348 39.3916 62.4136 39.0703 62.0173 39.0703C61.621 39.0703 61.2998 39.3916 61.2998 39.788C61.2998 40.1843 61.621 40.5056 62.0173 40.5056Z"
                fill="#0C0C0C"
              />
              <path
                d="M52.1547 44.9915C50.3846 45.0141 48.9257 43.1381 48.9026 41.3676C48.8795 39.5971 50.3026 38.6027 52.0727 38.5801L52.9998 39.7547L53.6761 44.9721L52.1547 44.9915Z"
                fill="#E98862"
              />
              <path
                d="M53.9151 42.1469C53.9151 42.1469 54.513 33.3914 57.1673 32.8509C58.9351 32.4908 63.222 34.9988 67.1927 34.2413C68.93 33.9109 70.5792 34.3401 70.7469 35.1607C70.8132 35.4857 71.8955 30.8151 69.1752 27.2715C67.0092 24.4507 59.9211 23.6528 56.6965 24.5778C47.7676 27.1399 51.7078 38.966 51.7078 38.966L52.1059 41.9843L53.9151 42.1469Z"
                fill="#0C0C0C"
              />
              <path
                d="M65.0435 44.8664C65.0473 44.8667 65.0511 44.8667 65.0549 44.8664C65.6253 44.8606 66.5813 44.7874 66.5775 44.3604C66.5737 44.0067 65.5676 40.1991 65.4525 39.7661C65.4398 39.7323 65.415 39.7045 65.383 39.688C65.3509 39.6715 65.3139 39.6675 65.2791 39.6768C65.2442 39.6861 65.2141 39.7079 65.1945 39.7382C65.1749 39.7685 65.1673 39.8049 65.173 39.8405C65.6199 41.5269 66.2856 44.1231 66.2884 44.364C66.2333 44.4656 65.6441 44.5701 65.0534 44.5762C65.0161 44.577 64.9806 44.5921 64.9541 44.6183C64.9277 44.6445 64.9123 44.6799 64.9112 44.7172C64.9101 44.7544 64.9233 44.7907 64.9482 44.8185C64.973 44.8462 65.0076 44.8634 65.0447 44.8664H65.0435Z"
                fill="#0C0C0C"
              />
              <path
                d="M66.9903 36.3367C67.5898 36.1716 68.2227 36.1716 68.8222 36.3367C69.1167 36.4158 69.3976 36.5388 69.6555 36.7016C69.9105 36.8609 70.1443 37.0518 70.3514 37.2698C70.3911 37.3083 70.4142 37.3608 70.4156 37.416C70.4171 37.4713 70.3969 37.5249 70.3594 37.5654C70.3218 37.606 70.2698 37.6302 70.2146 37.6329C70.1595 37.6356 70.1054 37.6165 70.0641 37.5799L70.0511 37.5687C69.8592 37.3911 69.6458 37.2384 69.4159 37.114C69.1903 36.9904 68.9473 36.9017 68.6951 36.851C68.1987 36.7467 67.6836 36.7754 67.2019 36.9344L67.1816 36.9415C67.1021 36.9696 67.0148 36.965 66.9387 36.9286C66.8626 36.8923 66.8041 36.8272 66.776 36.7477C66.7479 36.6682 66.7525 36.5808 66.7888 36.5047C66.8252 36.4287 66.8902 36.3701 66.9697 36.342L66.9903 36.3367Z"
                fill="#0C0C0C"
              />
              <path
                d="M62.7457 36.6964C62.2896 36.4746 61.7829 36.3774 61.2772 36.4145C61.0204 36.4312 60.7677 36.4867 60.5276 36.5792C60.2836 36.6719 60.0521 36.7948 59.8386 36.9451L59.8249 36.9543C59.7791 36.9837 59.7237 36.9943 59.6702 36.9839C59.6168 36.9735 59.5694 36.9429 59.538 36.8984C59.5065 36.854 59.4934 36.7992 59.5013 36.7453C59.5092 36.6914 59.5376 36.6427 59.5805 36.6092C59.8149 36.4208 60.0721 36.2627 60.346 36.1385C60.6234 36.0118 60.9181 35.9274 61.2205 35.888C61.5233 35.8454 61.8306 35.8446 62.1336 35.8857C62.4447 35.9244 62.7486 36.0071 63.0363 36.1314C63.1138 36.1651 63.1748 36.2282 63.2057 36.3069C63.2367 36.3855 63.2352 36.4732 63.2015 36.5507C63.1678 36.6282 63.1047 36.6892 63.0261 36.7202C62.9475 36.7512 62.8598 36.7496 62.7823 36.7159C62.7764 36.7134 62.7703 36.7106 62.7647 36.7075L62.7457 36.6964Z"
                fill="#0C0C0C"
              />
              <path
                d="M66.1371 30.3096C67.4473 30.0358 68.1828 28.2502 67.7799 26.3213C67.377 24.3924 65.9882 23.0507 64.678 23.3245C63.3678 23.5984 62.6323 25.384 63.0352 27.3129C63.4381 29.2417 64.8269 30.5834 66.1371 30.3096Z"
                fill="#0C0C0C"
              />
              <path
                d="M77.1024 199.302L96.2211 138.974H61.5657C54.6042 138.974 51.7021 133.07 51.7021 126.107C51.7021 119.145 58.5458 113.041 65.5069 113.041H112.279C116.525 113.041 121.285 115.638 123.616 119.188C125.946 122.739 125.316 128.24 123.628 132.136L93.0121 206.511"
                fill="#262626"
              />
              <path
                opacity="0.8"
                d="M77.1024 199.302L96.2211 138.974H61.5657C54.6042 138.974 51.7021 133.07 51.7021 126.107C51.7021 119.145 58.5458 113.041 65.5069 113.041H112.279C116.525 113.041 121.285 115.638 123.616 119.188C125.946 122.739 125.316 128.24 123.628 132.136L93.0121 206.511"
                fill="#0A0A0A"
              />
              <path
                d="M125.644 212.281L93.6766 138.975L51.7018 138.973C41.8664 138.201 37.362 132.81 37.5053 125.849C37.6465 118.976 41.1258 112.188 47.9661 112.188H104.541C109.602 112.292 113.584 117.886 115.461 122.588L142.037 207.794"
                fill="#262626"
              />
              <path
                d="M34.6426 112.271C34.6426 112.271 32.7785 124.43 35.7334 131.19C39.4222 139.63 53.2508 138.973 53.2508 138.973L52.4716 112.188L34.6426 112.271Z"
                fill="#262626"
              />
              <path
                d="M116.663 112.033L87.0133 105.887C85.7778 105.631 84.6268 105.068 83.6668 104.249C82.7069 103.43 81.9689 102.382 81.5213 101.202L69.8594 70.4641C69.5043 69.5416 69.3349 68.5581 69.361 67.5699C69.3871 66.5817 69.6081 65.6085 70.0113 64.706C70.4145 63.8035 70.9921 62.9896 71.7107 62.3111C72.4294 61.6325 73.275 61.1026 74.1989 60.7519C75.1229 60.4012 76.1071 60.2365 77.0949 60.2673C78.0827 60.2982 79.0547 60.5239 79.955 60.9316C80.8553 61.3392 81.6662 61.9208 82.3412 62.6429C83.0161 63.3649 83.5418 64.2133 83.888 65.1391L94.0895 92.0258L119.709 97.3356C119.709 97.3356 117.178 112.139 116.663 112.033Z"
                fill="#DA2F72"
              />
              <path
                d="M82.3706 78.2709C82.0284 82.2945 79.6012 107.396 79.2709 112.352H34.6621C34.4057 107.883 33.2548 88.3374 32.283 79.5954C31.3551 71.2547 30.0545 67.459 31.1231 64.8669C33.0078 60.2954 39.007 58.2538 43.0696 57.7038C45.5501 57.3679 49.2811 57.127 49.2811 57.127C49.2811 57.127 49.5972 63.519 57.1299 63.8748C67.384 64.3576 66.6992 57.127 66.75 57.127C68.4112 57.127 77.4536 58.3353 79.7146 59.8093C82.9082 61.8913 83.8519 64.7284 83.8892 67.3353C83.9321 70.3365 82.7588 73.7065 82.3706 78.2709Z"
                fill="#DA2F72"
              />
              <path
                d="M80.6175 98.7799H80.6068C80.5902 98.7785 80.574 98.7739 80.5591 98.7663C80.5443 98.7586 80.5311 98.7481 80.5203 98.7354C80.5095 98.7226 80.5014 98.7079 80.4963 98.692C80.4912 98.6761 80.4893 98.6593 80.4907 98.6427L81.6487 84.7282C81.6497 84.7114 81.654 84.695 81.6615 84.68C81.669 84.6649 81.6794 84.6516 81.6923 84.6407C81.7051 84.6298 81.72 84.6217 81.736 84.6168C81.7521 84.6118 81.769 84.6102 81.7857 84.6121C81.8023 84.6134 81.8185 84.6181 81.8334 84.6257C81.8482 84.6334 81.8614 84.6439 81.8722 84.6566C81.883 84.6693 81.8911 84.6841 81.8962 84.7C81.9013 84.7159 81.9032 84.7326 81.9018 84.7493L80.7437 98.6638C80.741 98.6954 80.7265 98.7249 80.7032 98.7464C80.6798 98.7679 80.6492 98.7799 80.6175 98.7799Z"
                fill="#0C0C0C"
              />
              <path
                d="M87.7884 109.592L97.5762 79.497C97.7483 78.9682 98.0832 78.5074 98.533 78.1806C98.9829 77.8538 99.5246 77.6778 100.081 77.6777H139.958C140.373 77.6777 140.783 77.7761 141.154 77.9649C141.524 78.1537 141.844 78.4275 142.089 78.7639C142.333 79.1003 142.494 79.4898 142.559 79.9005C142.624 80.3112 142.591 80.7314 142.462 81.1268L132.674 111.221C132.502 111.75 132.167 112.211 131.717 112.538C131.268 112.865 130.726 113.041 130.17 113.041H90.2928C89.8771 113.041 89.4673 112.942 89.0969 112.753C88.7266 112.565 88.4061 112.291 88.1618 111.954C87.9176 111.618 87.7564 111.229 87.6915 110.818C87.6266 110.407 87.6598 109.987 87.7884 109.592Z"
                fill="#292A2E"
              />
              <path
                opacity="0.2"
                d="M87.7884 109.592L97.5762 79.497C97.7483 78.9682 98.0832 78.5074 98.533 78.1806C98.9829 77.8538 99.5246 77.6778 100.081 77.6777H139.958C140.373 77.6777 140.783 77.7761 141.154 77.9649C141.524 78.1537 141.844 78.4275 142.089 78.7639C142.333 79.1003 142.494 79.4898 142.559 79.9005C142.624 80.3112 142.591 80.7314 142.462 81.1268L132.674 111.221C132.502 111.75 132.167 112.211 131.717 112.538C131.268 112.865 130.726 113.041 130.17 113.041H90.2928C89.8771 113.041 89.4673 112.942 89.0969 112.753C88.7266 112.565 88.4061 112.291 88.1618 111.954C87.9176 111.618 87.7564 111.229 87.6915 110.818C87.6266 110.407 87.6598 109.987 87.7884 109.592Z"
                fill="white"
              />
              <path
                d="M95.7731 110.6H69.0793C68.4142 110.6 67.875 111.139 67.875 111.804V111.835C67.875 112.5 68.4142 113.04 69.0793 113.04H95.7731C96.4382 113.04 96.9774 112.5 96.9774 111.835V111.804C96.9774 111.139 96.4382 110.6 95.7731 110.6Z"
                fill="#292A2E"
              />
              <path
                opacity="0.2"
                d="M95.7731 110.6H69.0793C68.4142 110.6 67.875 111.139 67.875 111.804V111.835C67.875 112.5 68.4142 113.04 69.0793 113.04H95.7731C96.4382 113.04 96.9774 112.5 96.9774 111.835V111.804C96.9774 111.139 96.4382 110.6 95.7731 110.6Z"
                fill="white"
              />
              <path
                d="M115.126 101.011C117.435 101.011 119.308 99.138 119.308 96.8277C119.308 94.5174 117.435 92.6445 115.126 92.6445C112.816 92.6445 110.943 94.5174 110.943 96.8277C110.943 99.138 112.816 101.011 115.126 101.011Z"
                fill="white"
              />
              <path
                d="M82.7746 72.6602H72.1709V85.6674H82.7746V72.6602Z"
                fill="white"
              />
              <path
                d="M72.1705 83.5069C71.3116 83.5069 70.4721 83.2522 69.758 82.7749C69.0439 82.2977 68.4873 81.6193 68.1587 80.8257C67.83 80.0321 67.744 79.1588 67.9116 78.3163C68.0791 77.4738 68.4927 76.6999 69.1 76.0924C69.7073 75.485 70.481 75.0714 71.3233 74.9038C72.1656 74.7362 73.0387 74.8222 73.8322 75.1509C74.6257 75.4797 75.3038 76.0364 75.781 76.7506C76.2581 77.4649 76.5128 78.3046 76.5128 79.1636C76.5115 80.3151 76.0536 81.4191 75.2396 82.2334C74.4255 83.0476 73.3217 83.5056 72.1705 83.5069ZM72.1705 76.5995C71.6634 76.5995 71.1677 76.7499 70.746 77.0317C70.3244 77.3135 69.9958 77.714 69.8018 78.1826C69.6077 78.6512 69.557 79.1668 69.6559 79.6642C69.7549 80.1617 69.9991 80.6186 70.3576 80.9773C70.7162 81.3359 71.1731 81.5801 71.6704 81.679C72.1678 81.7779 72.6833 81.7271 73.1518 81.533C73.6203 81.3389 74.0206 81.0101 74.3023 80.5884C74.584 80.1666 74.7343 79.6708 74.7343 79.1636C74.7336 78.4837 74.4633 77.8319 73.9826 77.3511C73.5019 76.8704 72.8502 76.6001 72.1705 76.5995Z"
                fill="white"
              />
              <path
                d="M72.7825 81.0919C72.6859 80.8407 72.5039 80.6315 72.2685 80.5009C72.0332 80.3704 71.7594 80.3269 71.4951 80.378C71.5002 80.3659 71.5028 80.3529 71.503 80.3397L71.8587 80.1806C72.1079 80.0746 72.3128 79.8858 72.4388 79.646C72.5648 79.4063 72.6041 79.1304 72.55 78.865C72.5127 78.6993 72.439 78.5439 72.3344 78.4101C72.2298 78.2763 72.0967 78.1674 71.9449 78.0913C71.7931 78.0151 71.6263 77.9736 71.4566 77.9697C71.2868 77.9658 71.1182 77.9997 70.9631 78.0688L70.1242 78.4441C70.1242 78.4408 70.1242 78.4375 70.1224 78.4342C70.1191 78.4275 70.115 78.4212 70.1102 78.4154C70.3169 78.2596 70.4647 78.0383 70.5295 77.7877C70.5944 77.537 70.5724 77.2718 70.4672 77.0353C70.2342 76.4048 69.4085 76.3544 68.8625 76.5982L66.6688 77.4833L67.3309 76.8116C67.5383 76.5926 67.6549 76.303 67.6571 76.0014C67.6593 75.6997 67.5469 75.4084 67.3426 75.1865C67.2331 75.0756 67.1021 74.9883 66.9577 74.9297C66.8133 74.8712 66.6585 74.8427 66.5027 74.846C66.3469 74.8492 66.1935 74.8842 66.0517 74.9488C65.9098 75.0134 65.7827 75.1062 65.6779 75.2215L57.8779 83.7419L60.5457 86.3117C61.374 87.1099 62.4219 87.6424 63.5548 87.8411C64.6877 88.0397 65.8542 87.8953 66.9046 87.4265L71.6588 85.2947C71.908 85.1887 72.1129 84.9999 72.2389 84.7601C72.3649 84.5204 72.4042 84.2444 72.3501 83.979C72.2961 83.7408 72.1675 83.5261 71.9829 83.3661C71.7984 83.2061 71.5676 83.1092 71.3241 83.0896C71.3268 83.0764 71.3269 83.0628 71.3243 83.0496C71.3217 83.0364 71.3165 83.0238 71.309 83.0127C71.3015 83.0015 71.2919 82.9919 71.2806 82.9845C71.2694 82.977 71.2568 82.9719 71.2436 82.9694L72.1214 82.5775C72.3994 82.4598 72.6222 82.241 72.745 81.9652C72.8677 81.6894 72.8811 81.3772 72.7825 81.0919Z"
                fill="#E98862"
              />
              <path
                opacity="0.2"
                d="M68.5379 79.1654C68.5127 79.1655 68.4883 79.1568 68.4689 79.1408C68.4495 79.1248 68.4363 79.1025 68.4316 79.0778C68.4269 79.0531 68.431 79.0275 68.4432 79.0055C68.4553 78.9834 68.4748 78.9664 68.4983 78.9572L69.982 78.3727C69.9952 78.3675 70.0093 78.365 70.0234 78.3653C70.0375 78.3655 70.0515 78.3685 70.0645 78.3742C70.0775 78.3798 70.0892 78.3879 70.099 78.3981C70.1089 78.4083 70.1166 78.4203 70.1218 78.4335C70.127 78.4466 70.1295 78.4607 70.1293 78.4748C70.129 78.489 70.126 78.503 70.1204 78.5159C70.1147 78.5289 70.1066 78.5407 70.0964 78.5505C70.0862 78.5603 70.0742 78.5681 70.0611 78.5732L68.5773 79.1577C68.5647 79.1627 68.5514 79.1653 68.5379 79.1654Z"
                fill="#0C0C0C"
              />
              <path
                opacity="0.2"
                d="M69.3662 81.2646C69.3413 81.2645 69.3171 81.2558 69.2978 81.2398C69.2786 81.2239 69.2655 81.2018 69.2607 81.1773C69.256 81.1528 69.2599 81.1274 69.2718 81.1054C69.2838 81.0835 69.3029 81.0663 69.3261 81.057L71.3556 80.2404C71.3818 80.2313 71.4105 80.2325 71.4357 80.244C71.461 80.2554 71.4809 80.2762 71.4912 80.3019C71.5016 80.3277 71.5016 80.3564 71.4913 80.3822C71.481 80.4079 71.4611 80.4287 71.4359 80.4402L69.4066 81.257C69.3937 81.262 69.38 81.2645 69.3662 81.2646Z"
                fill="#0C0C0C"
              />
              <path
                opacity="0.2"
                d="M70.2513 83.5416C70.2261 83.5416 70.2016 83.5328 70.1823 83.5166C70.1629 83.5004 70.1498 83.478 70.1453 83.4531C70.1408 83.4283 70.1451 83.4027 70.1576 83.3807C70.17 83.3587 70.1898 83.3419 70.2135 83.333L71.181 82.9699C71.2077 82.9597 71.2374 82.9607 71.2634 82.9724C71.2895 82.9842 71.3098 83.0059 71.3199 83.0326C71.33 83.0594 71.3291 83.0891 71.3173 83.1151C71.3056 83.1412 71.2839 83.1615 71.2572 83.1716L70.2897 83.5348C70.2774 83.5393 70.2644 83.5417 70.2513 83.5416Z"
                fill="#0C0C0C"
              />
              <path
                d="M75.0386 64.3866C74.9918 64.3867 74.9459 64.3738 74.9059 64.3494C74.866 64.325 74.8335 64.29 74.8122 64.2484C74.2337 63.1203 73.5139 61.716 73.5952 60.2438C73.6597 59.0731 74.2085 58.0329 74.7385 57.0283C75.081 56.4211 75.3729 55.7867 75.6112 55.1315C75.902 54.2275 75.9754 53.2675 75.8253 52.3298C75.6752 51.392 75.3059 50.5029 74.7474 49.7349C74.7265 49.7081 74.7111 49.6775 74.7023 49.6447C74.6934 49.6119 74.6912 49.5777 74.6958 49.544C74.7004 49.5104 74.7117 49.478 74.729 49.4488C74.7463 49.4196 74.7693 49.3941 74.7966 49.374C74.8239 49.3538 74.8549 49.3393 74.888 49.3313C74.921 49.3233 74.9552 49.3221 74.9887 49.3276C75.0222 49.3331 75.0543 49.3452 75.083 49.3633C75.1117 49.3815 75.1365 49.4051 75.1559 49.433C75.7626 50.2665 76.1639 51.2314 76.3273 52.2493C76.4906 53.2673 76.4114 54.3094 76.096 55.2909C75.8492 55.9731 75.5462 56.6337 75.19 57.2657C74.6615 58.2667 74.1625 59.2121 74.1041 60.272C74.0304 61.6065 74.6869 62.887 75.266 64.0166C75.2858 64.0553 75.2954 64.0985 75.2937 64.142C75.2921 64.1854 75.2794 64.2278 75.2567 64.2649C75.234 64.3021 75.2022 64.3327 75.1643 64.354C75.1263 64.3754 75.0836 64.3866 75.0401 64.3866H75.0386Z"
                fill="white"
              />
              <path
                d="M79.1178 68.3531C79.0615 68.3531 79.0068 68.3343 78.9622 68.2998C78.9177 68.2653 78.8859 68.217 78.8718 68.1625C78.8578 68.1079 78.8622 68.0502 78.8845 67.9985C78.9067 67.9467 78.9455 67.9039 78.9948 67.8766C79.9364 67.3572 80.0419 65.9114 79.5995 64.8138C79.3801 64.3094 79.1234 63.8219 78.8315 63.3557C78.4412 62.7515 78.124 62.1032 77.8863 61.4243C77.6437 60.5966 77.6284 59.7188 77.8422 58.8832C78.056 58.0476 78.491 57.285 79.1013 56.6757C79.1498 56.6294 79.2145 56.6042 79.2815 56.6055C79.3485 56.6068 79.4123 56.6345 79.4589 56.6826C79.5056 56.7307 79.5314 56.7952 79.5307 56.8622C79.53 56.9292 79.5029 56.9932 79.4552 57.0403C78.911 57.5838 78.5232 58.2638 78.3326 59.0089C78.142 59.754 78.1555 60.5368 78.3718 61.2749C78.5978 61.9153 78.8987 62.5268 79.2685 63.0965C79.5741 63.5844 79.8422 64.0949 80.0706 64.6235C80.5886 65.9086 80.4733 67.6413 79.24 68.3213C79.2026 68.342 79.1606 68.3529 79.1178 68.3531Z"
                fill="white"
              />
              <path
                d="M66.8211 90.506L29.4091 105.993C28.0602 106.545 26.5797 106.692 25.1485 106.417C23.7173 106.141 22.3974 105.454 21.3499 104.441C20.3025 103.427 19.5729 102.13 19.2504 100.708C18.9279 99.2865 19.0264 97.8017 19.5339 96.4351L30.6922 65.9514C32.1348 62.0663 36.1987 59.8323 40.0832 61.2755C41.9484 61.9684 43.462 63.3741 44.2911 65.1833C45.1202 66.9925 45.1967 69.057 44.504 70.9227L39.4144 85.3508L61.3408 76.8957"
                fill="#DA2F72"
              />
              <path
                d="M66.7295 90.6436C66.7096 90.6435 66.6901 90.6375 66.6736 90.6264C66.6571 90.6152 66.6442 90.5995 66.6365 90.5811L61.193 77.0615L39.3401 85.497C39.322 85.5041 39.3023 85.5057 39.2833 85.5016C39.2643 85.4976 39.2469 85.4882 39.2332 85.4744C39.2195 85.4607 39.2101 85.4432 39.2062 85.4242C39.2022 85.4052 39.2039 85.3855 39.211 85.3674L42.5902 76.6925C42.5998 76.6678 42.6188 76.648 42.6431 76.6373C42.6674 76.6267 42.6949 76.6261 42.7196 76.6357C42.7443 76.6454 42.7642 76.6644 42.7748 76.6887C42.7855 76.713 42.786 76.7405 42.7764 76.7652L39.4796 85.2284L61.2131 76.8389C61.2376 76.8295 61.2648 76.83 61.2889 76.8405C61.313 76.851 61.3321 76.8705 61.3419 76.8948L66.8222 90.5062C66.8318 90.5308 66.8313 90.5582 66.821 90.5826C66.8106 90.6069 66.7912 90.6263 66.7668 90.6365C66.7549 90.6412 66.7423 90.6436 66.7295 90.6436Z"
                fill="#0C0C0C"
              />
              <path
                d="M32.4777 88.1815C32.4543 88.1817 32.4315 88.1737 32.4134 88.1589C32.3954 88.144 32.3831 88.1233 32.3787 88.1003C32.3743 88.0772 32.3782 88.0534 32.3896 88.033C32.401 88.0126 32.4192 87.9968 32.4411 87.9884L39.2855 85.2946C39.2978 85.2896 39.311 85.287 39.3242 85.2871C39.3375 85.2872 39.3506 85.2899 39.3628 85.2951C39.375 85.3003 39.3861 85.3079 39.3953 85.3174C39.4046 85.3269 39.4119 85.3382 39.4167 85.3505C39.4216 85.3629 39.424 85.376 39.4237 85.3893C39.4234 85.4026 39.4205 85.4157 39.4151 85.4278C39.4098 85.4399 39.402 85.4509 39.3924 85.46C39.3827 85.4691 39.3714 85.4762 39.359 85.4809L32.5143 88.1747C32.5026 88.1792 32.4902 88.1815 32.4777 88.1815Z"
                fill="#0C0C0C"
              />
              <path
                d="M34.071 104.183C34.0478 104.183 34.0253 104.175 34.0074 104.161C33.9894 104.146 33.9771 104.126 33.9726 104.103C33.9681 104.08 33.9717 104.056 33.9827 104.036C33.9937 104.016 34.0114 104 34.0329 103.991L66.6925 90.4509C66.7047 90.4459 66.7177 90.4433 66.7308 90.4434C66.7439 90.4434 66.7569 90.446 66.769 90.451C66.7811 90.4561 66.7921 90.4634 66.8014 90.4727C66.8106 90.482 66.818 90.493 66.823 90.5052C66.828 90.5173 66.8306 90.5303 66.8305 90.5434C66.8305 90.5565 66.8279 90.5695 66.8229 90.5817C66.8178 90.5938 66.8105 90.6048 66.8012 90.614C66.7919 90.6233 66.7809 90.6307 66.7687 90.6357L34.1091 104.176C34.0971 104.181 34.0841 104.183 34.071 104.183Z"
                fill="#0C0C0C"
              />
              <path
                d="M199.881 185.674C199.814 185.674 199.749 185.647 199.701 185.599C199.343 185.241 198.76 178.672 197.188 159.113C196.545 151.11 195.938 143.551 195.494 138.957C195.463 138.629 195.336 138.591 195.294 138.578C194.687 138.395 192.841 139.851 191.811 141.125C191.769 141.178 191.707 141.211 191.64 141.218C191.573 141.225 191.506 141.206 191.454 141.163C191.401 141.121 191.368 141.059 191.361 140.992C191.354 140.925 191.373 140.858 191.416 140.806C192.097 139.963 194.318 137.754 195.44 138.091C195.666 138.16 195.948 138.356 196 138.908C196.444 143.506 197.051 151.067 197.695 159.072C198.65 170.958 199.732 184.423 200.087 185.271C200.115 185.309 200.131 185.354 200.134 185.401C200.138 185.447 200.128 185.494 200.107 185.536C200.086 185.577 200.053 185.612 200.013 185.637C199.974 185.661 199.928 185.674 199.881 185.674Z"
                fill="#494949"
              />
              <path
                d="M194.842 147.386C199.058 144.005 200.455 138.744 197.963 135.634C195.471 132.524 190.032 132.743 185.816 136.124C181.6 139.504 180.203 144.765 182.695 147.875C185.188 150.985 190.626 150.766 194.842 147.386Z"
                fill="#0CBC8B"
              />
              <path
                opacity="0.2"
                d="M194.842 147.386C199.058 144.005 200.455 138.744 197.963 135.634C195.471 132.524 190.032 132.743 185.816 136.124C181.6 139.504 180.203 144.765 182.695 147.875C185.188 150.985 190.626 150.766 194.842 147.386Z"
                fill="white"
              />
              <path
                d="M183.877 148.667C183.853 148.667 183.829 148.66 183.809 148.647C183.788 148.634 183.772 148.615 183.762 148.593C183.752 148.572 183.748 148.547 183.751 148.523C183.754 148.499 183.764 148.477 183.779 148.458L195.795 133.966C195.805 133.952 195.818 133.941 195.833 133.933C195.848 133.925 195.864 133.92 195.881 133.919C195.898 133.917 195.915 133.919 195.931 133.924C195.947 133.928 195.962 133.937 195.975 133.947C195.988 133.958 195.999 133.971 196.007 133.986C196.014 134.001 196.019 134.018 196.02 134.034C196.022 134.051 196.02 134.068 196.015 134.084C196.009 134.1 196.001 134.115 195.99 134.128L183.975 148.621C183.963 148.635 183.948 148.647 183.931 148.655C183.914 148.662 183.896 148.667 183.877 148.667Z"
                fill="white"
              />
              <path
                d="M202.501 182.567C202.461 182.567 202.421 182.558 202.385 182.539C202.349 182.52 202.318 182.493 202.294 182.46C202.271 182.427 202.255 182.389 202.25 182.349C202.244 182.309 202.248 182.268 202.261 182.23L206.775 169.247C206.907 168.865 207.134 168.523 207.433 168.252C207.732 167.981 208.095 167.789 208.488 167.694C208.88 167.6 209.29 167.605 209.68 167.71C210.07 167.815 210.428 168.016 210.72 168.294L213.106 170.574C213.155 170.621 213.183 170.685 213.184 170.752C213.186 170.82 213.161 170.885 213.114 170.934C213.068 170.983 213.004 171.011 212.936 171.012C212.869 171.014 212.804 170.988 212.755 170.942L210.369 168.662C210.138 168.442 209.856 168.283 209.548 168.201C209.24 168.118 208.917 168.114 208.607 168.189C208.297 168.263 208.011 168.415 207.774 168.629C207.538 168.843 207.359 169.113 207.254 169.414L202.741 182.396C202.724 182.446 202.691 182.49 202.648 182.52C202.605 182.551 202.554 182.567 202.501 182.567Z"
                fill="#494949"
              />
              <path
                d="M219.773 177.761C222.898 175.288 222.712 169.847 219.358 165.609C216.005 161.372 210.753 159.942 207.629 162.416C204.505 164.889 204.69 170.33 208.044 174.567C211.397 178.805 216.649 180.235 219.773 177.761Z"
                fill="#0CBC8B"
              />
              <path
                d="M197.831 184.844C197.774 184.844 197.719 184.825 197.674 184.79C197.629 184.754 197.597 184.705 197.584 184.649L190.01 152.974C189.959 152.758 189.848 152.56 189.692 152.403C189.535 152.246 189.338 152.135 189.122 152.082C188.906 152.03 188.68 152.038 188.469 152.105C188.258 152.173 188.069 152.298 187.924 152.466L185.589 155.164C185.567 155.189 185.541 155.21 185.511 155.225C185.481 155.24 185.448 155.249 185.415 155.251C185.382 155.254 185.348 155.25 185.317 155.239C185.285 155.229 185.256 155.212 185.23 155.19C185.205 155.168 185.185 155.142 185.17 155.112C185.155 155.082 185.146 155.049 185.143 155.016C185.141 154.983 185.145 154.949 185.156 154.918C185.166 154.886 185.183 154.857 185.205 154.831L187.539 152.133C187.746 151.895 188.014 151.718 188.315 151.622C188.615 151.526 188.936 151.515 189.242 151.59C189.549 151.665 189.828 151.823 190.051 152.046C190.273 152.269 190.43 152.55 190.503 152.857L198.077 184.532C198.093 184.598 198.082 184.667 198.047 184.724C198.011 184.782 197.955 184.823 197.889 184.838C197.87 184.843 197.851 184.845 197.831 184.844Z"
                fill="#494949"
              />
              <path
                d="M195.037 162.202C195.957 156.876 193.521 152.008 189.594 151.329C185.668 150.65 181.738 154.417 180.817 159.742C179.897 165.068 182.333 169.935 186.26 170.614C190.187 171.294 194.116 167.527 195.037 162.202Z"
                fill="#0CBC8B"
              />
              <path
                d="M199.882 185.674C199.871 185.674 199.861 185.673 199.85 185.672C199.783 185.664 199.722 185.629 199.681 185.576C199.639 185.523 199.621 185.456 199.629 185.389L204.466 146.112C204.518 145.683 204.693 145.28 204.97 144.949C205.246 144.618 205.613 144.374 206.025 144.247C206.436 144.119 206.876 144.113 207.291 144.23C207.707 144.347 208.079 144.581 208.364 144.904L213.892 151.167C213.937 151.217 213.96 151.283 213.956 151.351C213.951 151.418 213.921 151.481 213.87 151.525C213.82 151.57 213.753 151.593 213.686 151.589C213.619 151.584 213.556 151.554 213.511 151.503L207.983 145.24C207.763 144.99 207.475 144.809 207.154 144.719C206.833 144.629 206.493 144.634 206.174 144.732C205.856 144.831 205.573 145.02 205.359 145.276C205.146 145.532 205.01 145.844 204.97 146.175L200.132 185.451C200.125 185.512 200.095 185.569 200.049 185.61C200.003 185.651 199.943 185.674 199.882 185.674Z"
                fill="#494949"
              />
              <path
                d="M219.561 154.646C221.49 151.159 219.22 146.211 214.492 143.595C209.764 140.979 204.367 141.686 202.439 145.173C200.51 148.661 202.779 153.609 207.507 156.225C212.236 158.841 217.632 158.134 219.561 154.646Z"
                fill="#0CBC8B"
              />
              <path
                opacity="0.2"
                d="M219.773 177.761C222.898 175.288 222.712 169.847 219.358 165.609C216.005 161.372 210.753 159.942 207.629 162.416C204.505 164.889 204.69 170.33 208.044 174.567C211.397 178.805 216.649 180.235 219.773 177.761Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M195.037 162.202C195.957 156.876 193.521 152.008 189.594 151.329C185.668 150.65 181.738 154.417 180.817 159.742C179.897 165.068 182.333 169.935 186.26 170.614C190.187 171.294 194.116 167.527 195.037 162.202Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M219.561 154.646C221.49 151.159 219.22 146.211 214.492 143.595C209.764 140.979 204.367 141.686 202.439 145.173C200.51 148.661 202.779 153.609 207.507 156.225C212.236 158.841 217.632 158.134 219.561 154.646Z"
                fill="white"
              />
              <path
                d="M206.067 219H193.695L191.645 179.406H208.117L206.067 219Z"
                fill="#292A2E"
              />
              <path
                opacity="0.3"
                d="M206.067 219H193.695L191.645 179.406H208.117L206.067 219Z"
                fill="white"
              />
              <path
                d="M219.773 177.887C219.754 177.887 219.735 177.883 219.717 177.875C219.7 177.866 219.685 177.854 219.673 177.839L207.528 162.493C207.508 162.467 207.498 162.433 207.502 162.4C207.506 162.366 207.523 162.336 207.549 162.315C207.576 162.294 207.609 162.284 207.643 162.288C207.676 162.292 207.707 162.309 207.728 162.335L219.872 177.681C219.887 177.7 219.896 177.722 219.899 177.746C219.902 177.77 219.898 177.794 219.887 177.815C219.877 177.837 219.861 177.855 219.84 177.868C219.82 177.88 219.797 177.887 219.773 177.887Z"
                fill="white"
              />
              <path
                d="M218.754 155.876C218.727 155.876 218.7 155.867 218.679 155.85L203.138 144.501C203.125 144.491 203.113 144.479 203.104 144.465C203.096 144.451 203.09 144.435 203.087 144.418C203.085 144.402 203.086 144.385 203.09 144.369C203.093 144.352 203.101 144.337 203.11 144.324C203.12 144.31 203.133 144.299 203.147 144.29C203.161 144.281 203.177 144.276 203.193 144.273C203.21 144.27 203.227 144.271 203.243 144.275C203.259 144.279 203.275 144.286 203.288 144.296L218.829 155.646C218.85 155.662 218.865 155.684 218.873 155.709C218.881 155.735 218.881 155.762 218.873 155.787C218.865 155.813 218.849 155.835 218.827 155.85C218.806 155.866 218.78 155.874 218.754 155.875V155.876Z"
                fill="white"
              />
              <path
                d="M185.629 170.45C185.618 170.45 185.607 170.448 185.596 170.446C185.564 170.437 185.536 170.416 185.519 170.386C185.502 170.357 185.498 170.323 185.506 170.29L190.454 151.751C190.458 151.734 190.465 151.719 190.475 151.705C190.485 151.692 190.498 151.68 190.512 151.672C190.527 151.663 190.543 151.658 190.56 151.655C190.576 151.653 190.593 151.654 190.61 151.659C190.626 151.663 190.641 151.671 190.654 151.681C190.668 151.691 190.679 151.704 190.687 151.719C190.696 151.733 190.701 151.749 190.703 151.766C190.705 151.783 190.704 151.8 190.699 151.816L185.752 170.356C185.745 170.383 185.729 170.407 185.706 170.424C185.684 170.441 185.657 170.45 185.629 170.45Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_87_2308">
                <rect width="222" height="219" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <h2 data-aos="fade-up" data-aos-delay="100">
          Welcome to csPILLAI
        </h2>
        <span data-aos="fade-up" data-aos-delay="200">
          Invest to exit site
        </span>
        <button
          onClick={toggleVisibility}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default Popup;
