const urls = {
  auth: {
    login: "/investors/login",
    register: "/investors/register",
    forgotPassword: "/investors/request-password-reset",
    requestReset: "/investors/request-password-reset",
  },
  profile: {
    me: "/investors/me",
    createPaymentProfile: "/investors/create-payment-profile",
    updateAge: "/investors/profile/update-age",
    updateGender: "/investors/profile/update-gender",
    updateBasic: "/investors/profile/sba-info",
  },
  service: {
    baseUrl: "/services/invest2exit",
    schemas: "/services/invest2exit/schemas",
    ideas: "/services/invest2exit/ideas",
    fund: "/fund",
    collect: "/collect",
  },
  fileStreams: {
    baseUrl: "/file-streams",
  },
};

export default urls;
