import React from "react";
import Button from "../components/Button";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router";

export default function Submit({ disabled = false, page, ...others }) {
  const { handleSubmit, isValid } = useFormikContext();
  const navigate = useNavigate();

  const handleClick = () => {
    handleSubmit();
    navigate(page);
  };

  return (
    <Button
      {...others}
      onClick={handleClick}
      disabled={!isValid || disabled}
      // loading={isSubmitting}
    />
  );
}
