import Avater from "../components/Avater";
import ClearHead from "../components/ClearHead";
import "../css/auth.css";
import { Submit, Form, FormInput } from "../form";
import * as Yup from "yup";
import useClient from "../hooks/useClient";
import { Link, useNavigate } from "react-router-dom";
import urls from "../api/urls";
import { useState } from "react";
import Error from "../form/Error";
import countries from "../tools/countries";
import CheckBox from "../components/CheckBox";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email address"),
  password: Yup.string().required().label("Password"),
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  country: Yup.string().required().label("Country"),
  state: Yup.string().label("State"),
  address: Yup.string().required().label("Address"),
  age: Yup.number().required().label("Age"),
});

function Register(props) {
  const [isMale, setIsMale] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { isLoading, post } = useClient();
  const navigate = useNavigate();
  const handleRegister = async (fdata) => {
    const {
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      country,
      state,
      address,
      age,
    } = fdata;
    setErrorMessage(null);
    const { error, data } = await post(urls.auth.register, {
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      gender: isMale ? "Male" : isMale === false ? "Female" : "Other",
      age,
      contact: {
        country,
        state,
        address: {
          line1: address,
        },
      },
    });
    if (error) return setErrorMessage(error.message);
    const accessToken = data.data.tokens.accessToken;
    const pres = await post(
      urls.profile.createPaymentProfile,
      {
        country,
        state,
        address,
        phoneNumber,
      },
      {
        headers: {
          "x-access-token": accessToken,
        },
      }
    );
    console.log(pres);
    navigate("/login");
  };

  return (
    <>
      <ClearHead />
      <div className="container manrope containerh auth_container">
        <div className="form register">
          <Avater />
          <h2>Create an account</h2>
          <Form
            onSubmit={handleRegister}
            validationSchema={validationSchema}
            initialValues={{
              email: "",
              password: "",
              firstName: "",
              lastName: "",
              country: "",
              state: "",
              address: "",
            }}
          >
            <div className="inputs">
              <FormInput
                label="First Name"
                placeholder="First Name"
                name="firstName"
              />
              <FormInput
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
              />
            </div>
            <div className="inputs">
              <FormInput
                label="Email"
                placeholder="Email"
                type="email"
                name="email"
              />
              <FormInput
                label="Phone Number"
                placeholder="Phone Number"
                name="phoneNumber"
              />
            </div>
            <div className="flex">
              <CheckBox
                checked={isMale === true}
                onChange={() => setIsMale(true)}
                className="fcheck"
                label="Male"
              />
              <CheckBox
                checked={isMale === false}
                onChange={() => setIsMale(false)}
                className="fcheck"
                label="Female"
              />
            </div>
            <FormInput
              label="Password"
              placeholder="Password"
              name="password"
              isPassword
            />
            <div className="inputs">
              <FormInput
                name="country"
                label="Country"
                placeholder="Select country"
                select
                options={countries.map((c) => ({
                  value: c.name,
                  label: c.name,
                }))}
              />
              <FormInput name="state" label="State" placeholder="State" />
            </div>
            <div className="inputs">
              <FormInput name="address" placeholder="Address" label="Address" />
              <FormInput
                name="age"
                type="number"
                placeholder="Age"
                label="Age"
              />
            </div>
            <Error error={errorMessage} touched />
            <Submit loading={isLoading} className="auth_btn" title="Sign up" />
          </Form>
          <span>
            Already have an account? <Link to="/login">Login</Link>
          </span>
        </div>
      </div>
    </>
  );
}

export default Register;
