import { useNavigate } from "react-router";
import "../css/payment-status.css";

function PaymentFailure() {
  const navigate = useNavigate();
  
  return (
    <div className="payment-status">
      <svg
        width="216"
        height="216"
        viewBox="0 0 216 216"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M108 18C58.23 18 18 58.23 18 108C18 157.77 58.23 198 108 198C157.77 198 198 157.77 198 108C198 58.23 157.77 18 108 18ZM146.7 146.7C145.867 147.534 144.878 148.196 143.79 148.648C142.701 149.1 141.534 149.332 140.355 149.332C139.176 149.332 138.009 149.1 136.92 148.648C135.832 148.196 134.843 147.534 134.01 146.7L108 120.69L81.99 146.7C80.3072 148.383 78.0248 149.328 75.645 149.328C73.2652 149.328 70.9828 148.383 69.3 146.7C67.6172 145.017 66.6718 142.735 66.6718 140.355C66.6718 139.177 66.9039 138.01 67.3549 136.921C67.8058 135.832 68.4668 134.843 69.3 134.01L95.31 108L69.3 81.99C67.6172 80.3072 66.6718 78.0248 66.6718 75.645C66.6718 73.2652 67.6172 70.9828 69.3 69.3C70.9828 67.6172 73.2652 66.6718 75.645 66.6718C78.0248 66.6718 80.3072 67.6172 81.99 69.3L108 95.31L134.01 69.3C134.843 68.4668 135.832 67.8058 136.921 67.3549C138.01 66.9039 139.177 66.6718 140.355 66.6718C141.533 66.6718 142.7 66.9039 143.789 67.3549C144.878 67.8058 145.867 68.4668 146.7 69.3C147.533 70.1332 148.194 71.1224 148.645 72.2111C149.096 73.2998 149.328 74.4666 149.328 75.645C149.328 76.8234 149.096 77.9902 148.645 79.0789C148.194 80.1676 147.533 81.1568 146.7 81.99L120.69 108L146.7 134.01C150.12 137.43 150.12 143.19 146.7 146.7Z"
          fill="#EF0000"
        />
      </svg>

      <h2 className="fail">Failed</h2>
      <p>
        If you are having trouble funding this idea, please try again or get in
        touch with our customer support.
      </p>
      <button onClick={() => navigate("/")}>Go to homepage</button>
    </div>
  );
}

export default PaymentFailure;
