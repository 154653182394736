import { useContext, useState } from "react";
import client from "../api/client";
import { RequestTypes, responseTypes } from "../api/request";
import urls from "../api/urls";
import AppContext from "../contexts/AppContext";
import store from "../utils/store";

function useClient() {
  const [isLoading, setIsLoading] = useState(false);
  const app_context = useContext(AppContext);
  const Request = async (type, ...args) => {
    const response = { error: null, data: null };
    if (!Object.values(RequestTypes).includes(type)) {
      response.error = { message: { message: "Invalid Request Type" } };
      return response;
    }
    try {
      setIsLoading(true);
      const res = await client[type](...args);
      if (res.data.type === responseTypes.error) {
        response.error = res.data;
        return response;
      }
      response.data = res.data;
    } catch (error) {
      response["error"] = error?.response?.data;
    } finally {
      setIsLoading(false);
    }
    return response;
  };

  const get = (...args) => {
    return Request(RequestTypes.GET, ...args);
  };
  const post = (...args) => {
    return Request(RequestTypes.POST, ...args);
  };
  const put = (...args) => {
    return Request(RequestTypes.PUT, ...args);
  };
  const fdelete = (...args) => {
    return Request(RequestTypes.DELETE, ...args);
  };
  const patch = (...args) => {
    return Request(RequestTypes.PATCH, ...args);
  };

  const restoreUser = async () => {
    const token = store.getAccessToken();
    if (!token) return null;

    const response = await get(urls.profile.me);
    if (response.error) return null;
    return response.data.data.profile;
  };

  const logout = () => {
    store.removeTokens();
    app_context.setUser(null);
    window.location = "/";
  };

  const getSchemas = () => {
    // const { data, error } = await get(urls.service.schemas);
    // if (error) return [];
    // return data.data.schemas;
    return schemaList;
  };
  const getIdeas = async () => {
    const { data, error } = await get(urls.service.ideas);
    if (error) return [];
    return data.data.ideas;
  };

  return {
    isLoading,
    get,
    post,
    put,
    fdelete,
    patch,
    Request,
    restoreUser,
    logout,
    getIdeas,
    getSchemas,
  };
}

export default useClient;

const schemaList = [
  {
    _id: "62e12d46184b35ccd96e238e",
    title: "gooty-SB",
    paymentPercentage: 3,
    points: [
      "Invest using a Convertible Note for 25Lacs",
      "Pre-Money valuation cap of 5Cr",
      "Interest at the rate of 9% annum",
      "Estimated time to Series A investment triggering conversion of Convertible Note is 18 months",
      "Targeted Series A equity investment of 5Cr",
      "Targeted Series A equity ownership of 30% ",
      "Targeted Equity for Note Holder post conversion of 3%+",
    ],
    agreements: [
      "Startup Builder Agreement (SBA)",
      "Note Purchase Agreement (NPA)",
      "Convertible Note (CN)",
    ],
    __v: 0,
    paymentAmount: 2500000,
    pnumbers: [1000000, 1000000, 500000],
    dEqv: 32500,
  },
  {
    _id: "62e12d5e184b35ccd96e2391",
    title: "gooty-SS",
    paymentPercentage: 6,
    points: [
      "Invest using a Convertible Note for 50Lacs",
      "Pre-Money valuation cap of 5Cr",
      "Interest at the rate of 9% annum",
      "Estimated time to Series A investment triggering conversion of Convertible Note is 18 months",
      "Targeted Series A equity investment of 5Cr",
      "Targeted Series A equity ownership of 30% ",
      "Targeted Equity for Note Holder post conversion of 6%+",
    ],
    agreements: [
      "Startup Builder Agreement(SBA)",
      "Note Purchase Agreement (NPA)",
      "Convertible Note (CN)",
    ],
    __v: 0,
    paymentAmount: 5000000,
    pnumbers: [1000000, 1000000, 1000000, 1000000, 1000000],
    dEqv: 65000,
  },
  {
    _id: "62e12d73184b35ccd96e2394",
    title: "gooty-CI ",
    paymentPercentage: 12,
    points: [
      "Invest using a Convertible Note for 100Lacs",
      "Pre-Money valuation cap of 5Cr",
      "Interest at the rate of 9% annum",
      "Estimated time to Series A investment triggering conversion of Convertible Note is 18 months",
      "Targeted Series A equity investment of 5Cr",
      "Targeted Series A equity ownership of 30% ",
      "Targeted Equity for Note Holder post conversion of 12%+",
    ],
    agreements: [
      "Startup Builder Agreement (SBA)",
      "Note Purchase Agreement (NPA)",
      "Convertible Note (CN)",
    ],
    __v: 0,
    paymentAmount: 10000000,
    pnumbers: [1000000, 1000000, 1000000, 1000000, 1000000],
    dEqv: 130000,
  },
];
