import Avater from "../components/Avater";
import ClearHead from "../components/ClearHead";
import "../css/auth.css";
import { Submit, Form, FormInput } from "../form";
import * as Yup from "yup";
import useClient from "../hooks/useClient";
import { Link, useLocation, useNavigate } from "react-router-dom";
import urls from "../api/urls";
import { useState } from "react";
import Error from "../form/Error";
import { useContext } from "react";
import AppContext from "../contexts/AppContext";
import store from "../utils/store";
import { useEffect } from "react";

const validationSchema = Yup.object().shape({
  emaill: Yup.string().email().required().label("Email address"),
  password: Yup.string().required().label("Password"),
});

function Login(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const { isLoading, post } = useClient();
  const { user, setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const handleLogin = async ({ emaill: email, password }) => {
    setErrorMessage(null);
    const { error, data } = await post(urls.auth.login, { email, password });
    if (error) return setErrorMessage(error.message);
    store.setTokens(data.data.tokens);
    setUser(data.data.profile);
    navigate("/");
    window.location.reload();
  };

  const { search } = useLocation();
  const next = search.replace("?next=", "");

  useEffect(() => {
    if (user) {
      if (
        !user.age ||
        !user.phoneNumber ||
        !user.contact.address.line1 ||
        !user.gender === "Other" ||
        !user.tazapayId
      )
        window.location = "/profile/update";
      else window.location = next ? next : "/";
    }
  }, [user]);

  return (
    <>
      <ClearHead />
      <div className="container manrope containerh auth_container">
        <div className="form">
          <Avater />
          <h2>Sign in</h2>
          <Form
            onSubmit={handleLogin}
            validationSchema={validationSchema}
            initialValues={{
              emaill: "",
              password: "",
            }}
          >
            <FormInput
              label="Email"
              placeholder="Email"
              type="email"
              name="emaill"
            />
            <FormInput
              label="Password"
              placeholder="Password"
              name="password"
              isPassword
            />
            <Link className="fpassword" to="/forgot-password">
              Forgot Password?
            </Link>
            <Error error={errorMessage} touched />
            <Submit loading={isLoading} className="auth_btn" title="Login" />
          </Form>
          <span>
            Don't have an account? <Link to="/register">Sign up</Link>
          </span>
        </div>
      </div>
    </>
  );
}

export default Login;
