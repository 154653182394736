import { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../contexts/AppContext";
import "../css/hero.css";
import ClearHead from "./ClearHead";

function Hero(props) {
  const { user } = useContext(AppContext);
  return (
		<>
			<ClearHead />
			<div id="home" className="ubuntu container hero">
				<h2 data-aos="fade-down" data-aos-delay="100">
					<span>Seed funding </span> -{'>'} Non-tradable Convertible Note
					Financings or Investment Options
				</h2>
				<p data-aos="fade-down" data-aos-delay="200">
					Are you a pre-seed fund? Do you make lots of investments? Do you want
					to invest straight away? Take the leap of faith first <br />{' '}
					Welcome to INVEST2EXIT! <br />
					(✓)Invest, exit, & gain (✓)Make Multifold Returns
					Effortlessly (✓)Practice Angel Investing On csPILLAI (✓)Visit & Pick
					Your Investment Option (✓)Choose & Fund A Tech Idea
					Inexpensively (✓)Track Investment Progress Real-time (✓)Avail 540-days
					Exit Option @ 2x Payout*
				</p>
				<Link
					data-aos="fade-down"
					data-aos-delay="300"
					to={user ? '#investmentoptions' : '/register'}
					className="btb_h"
				>
					Get Started
				</Link>
			</div>
		</>
	);
}

export default Hero;
