import { useNavigate } from "react-router";
import "../css/payment-status.css";

function PaymentSuccess() {
  const navigate = useNavigate();

  return (
    <div className="payment-status">
      <svg
        width="216"
        height="216"
        viewBox="0 0 216 216"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3_4390)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 108C0 79.3566 11.3785 51.8864 31.6325 31.6325C51.8864 11.3785 79.3566 0 108 0C136.643 0 164.114 11.3785 184.368 31.6325C204.621 51.8864 216 79.3566 216 108C216 136.643 204.621 164.114 184.368 184.368C164.114 204.621 136.643 216 108 216C79.3566 216 51.8864 204.621 31.6325 184.368C11.3785 164.114 0 136.643 0 108H0ZM101.837 154.224L164.016 76.4928L152.784 67.5072L99.7632 133.762L62.208 102.47L52.992 113.53L101.837 154.238V154.224Z"
            fill="#5DAF1D"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_4390">
            <rect width="216" height="216" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <h2>Success</h2>
      <p>
        Congratulations you have successfully funded an idea, You will receive
        an email notification shortly
      </p>
      <button onClick={() => navigate(`/`)}>
        Fund another idea
      </button>
    </div>
  );
}

export default PaymentSuccess;
