import Loader from "./Loader";

function Button({ title, loading, className = "", ...props }) {
  return (
    <div {...props} className={`btn manrope ${className}`}>
      {loading ? <Loader loading /> : title}
    </div>
  );
}

export default Button;
