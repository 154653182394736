import Title from "./Title";
import "../css/schemas.css";
import Schema from "./Schema";
import { useContext } from "react";
import AppContext from "../contexts/AppContext";

function Schemas(props) {
  const { schemas } = useContext(AppContext);
  return (
    <div id="investmentoptions" className="container manrope schemas">
      <Title className="cn" title="Select an Investment Option" />
      <span className="ech" data-aos="fade-up" data-aos-delay="200">
        You are a gooty-option away from owning (to exit) a startup or becoming
        an (angel) investor!
      </span>
      <br />
      <div className="schemas_list">
        {schemas.map((s, idx) => (
          <Schema
            {...s}
            className={idx === schemas.length - 1 ? "last" : ""}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
}

export default Schemas;
