import React from "react";
import Input from "../components/Input";
import { useFormikContext } from "formik";

export default function FormInput({ name, ...others }) {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext();
  return (
    <Input
      {...others}
      onBlur={() => setFieldTouched(name)}
      onChange={(value) => setFieldValue(name, value)}
      value={values[name]}
      error={errors[name]}
      touched={touched[name]}
      name={name}
    />
  );
}
