import { useState } from "react";
import Avater from "../components/Avater";
import ClearHead from "../components/ClearHead";
import "../css/auth.css";
import { Submit } from "../form";
import Error from "../form/Error";
import Form from "../form/Form";
import FormInput from "../form/FormInput";
import useClient from "../hooks/useClient";
import * as Yup from "yup";
import urls from "../api/urls";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email address"),
});

function RecoverAccount(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const { isLoading, post } = useClient();
  const handleSubmit = async (fdata) => {
    setErrorMessage(null);
    const { error, data } = await post(urls.auth.requestReset, fdata);
    console.log(data);
    console.log(error);
    if (error) alert(error.message);
    if (error) return setErrorMessage(error.message);
    alert("Reset link sent!");
  };
  return (
    <>
      <ClearHead />
      <div className="container containerh auth_container">
        <div className="form">
          <Avater />
          <h2>Recover Account</h2>
          <Form
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={{ email: "" }}
          >
            <FormInput placeholder="Email" type="email" name="email" />
            <Error error={errorMessage} touched />
            <Submit
              loading={isLoading}
              className="auth_btn"
              title="Request Link"
            />
          </Form>
        </div>
      </div>
    </>
  );
}

export default RecoverAccount;
