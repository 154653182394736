import RenderCheck from './RenderCheck';
import Title from './Title';
import '../css/benefits.css';

const benefits = [
	'Section 62(3) complied convertible notes ',
	'Tax exempts & transferable debt notes',
	'A 2x liquidation or 59% CAGR of returns ',
	'Good income route & pay back/conversion',
	'Amazing 540 days startup marathon finish',
	'Access to progress tracking e-dashboards',
	'5-star financially transparent e-reportings',
];

function Benefits(props) {
	return (
		<div id="benefits" className="container manrope benefits">
			<div className="yt" data-aos="fade-up" data-aos-delay="200">
				<div className="iframe">
					<iframe
						src="https://www.youtube.com/embed/RzL_WAaV8N4"
						title="How it works"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
			</div>
			<div className="ben" data-aos="fade-up" data-aos-delay="400">
				<Title title="Benefits" />
				<ul className="bn_ul">
					{benefits.map((b, idx) => (
						<li key={idx}>
							<RenderCheck />
							<span>{b}</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default Benefits;
