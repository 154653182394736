import { useState } from 'react';
import '../css/share.css';

function Share(props) {
	const [comment, setComment] = useState('');
	return (
		<div id="share" className="container manrope flex mfc share_c containerh">
			<div className="sh_txh" data-aos="fade-up">
				<h2>
					Do you like <br className="desktop" /> csPILLAI ?
				</h2>
				<p>Comment and share csPILLAI with your friends</p>
			</div>
			<div className="inps" data-aos="fade-up">
				<textarea
					onChange={(e) => setComment(e.target.value)}
					name="comment"
					id="comment"
					placeholder="Your Comment"
				></textarea>
				<div className="sss">
					<a
						target="_blank"
						rel="noreferrer"
						href={`https://twitter.com/intent/tweet?text=${comment}&url=manufacturingbymasses.cspillai.com`}
					>
						<i className="fab fa-twitter"></i>
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href={`https://www.facebook.com/sharer/sharer.php?u=manufacturingbymasses.cspillai.com&quote=${comment}`}
					>
						<i className="fab fa-facebook"></i>
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href={`https://wa.me/?text=${comment}%5Cn%20manufacturingbymasses.cspillai.com`}
					>
						<i className="fab fa-whatsapp"></i>
					</a>

					<a
						target="_blank"
						rel="noreferrer"
						href={`https://t.me/share/url?url=manufacturingbymasses.cspillai.com&text=${comment}`}
					>
						<i className="fab fa-telegram"></i>
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href={`https://www.tumblr.com/widgets/share/tool?canonicalUrl=manufacturingbymasses.cspillai.com&caption=${comment}&tags=test%2Chello`}
					>
						<i className="fab fa-tumblr"></i>
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href={`https://www.reddit.com/submit?url=manufacturingbymasses.cspillai.com&title=${comment}`}
					>
						<i className="fab fa-reddit"></i>
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href={`https://www.linkedin.com/sharing/share-offsite/?url=manufacturingbymasses.cspillai.com`}
					>
						<i className="fab fa-linkedin"></i>
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href={`https://mail.google.com/mail/u/0/?view=cm&to&su=${comment}&body=https%3A%2F%2Fmanufacturingbymasses.cspillai.com%0A&bcc&cc&fs=1&tf=1`}
					>
						<i className="fab fa-google"></i>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Share;
