import "../css/loader.css";

function Loader({ loading, className = "" }) {
  if (!loading) return null;
  return (
    <div className={`loader flex justify-center align-center ${className}`}>
      <div></div>
    </div>
  );
}

export default Loader;
