import axios from "axios";
import configs from "../configs/configs";
import store from "../utils/store";

const client = axios.create({
  baseURL: configs.BACKEND_URL,
  headers: {
    "x-access-token": store.getAccessToken(),
    "x-refresh-token": store.getRefreshToken(),
  },
});

export default client;
