function RenderLogo({ ...props }) {
  return (
    <div {...props}>
      <svg
        width="47"
        height="45"
        viewBox="0 0 47 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.3482 10.3279L23.6277 6.39651L8.55707 13.5786L10.4728 30.9726L23.6277 38.3791L38.1875 29.7382L38.8261 26.1471L23.6277 34.788V18.5162L47 7.37705L46.3614 11.8953L28.2255 20.8728L27.8424 27.4938L45.9783 18.5162L43.8071 32.6559L23.6277 45L4.08696 33.6658L0 10.0998L22.8614 0L46.3614 5.90164L37.3482 10.3279Z"
          fill="url(#paint0_linear_53_8278)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_53_8278"
            x1="23.2446"
            y1="2.01995"
            x2="23.2446"
            y2="45"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F64C3B" />
            <stop offset="1" stopColor="#B80CB6" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default RenderLogo;
