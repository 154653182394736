import { useEffect } from "react";
import { useState } from "react";
import "../css/checkbox.css";

function CheckBox({ label, checked, onChange = () => {}, className = "" }) {
  const [isCheked, setIsCheked] = useState(false);
  const toggleCheck = () => {
    onChange(!isCheked);
    setIsCheked(!isCheked);
  };

  useEffect(() => {
    setIsCheked(checked);
  }, [checked]);

  return (
    <div className={`flex ch ${className}`}>
      <div onClick={toggleCheck} className="bx">
        {isCheked ? (
          <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2969 1.64062H1.64062V19.3594H19.3594V13.4531"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.70312 10.0781L10.9219 14.2969L21.0469 3.32812"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2969 1.64062H1.64062V19.3594H19.3594V13.4531"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      <span onClick={toggleCheck}>{label}</span>
    </div>
  );
}

export default CheckBox;
