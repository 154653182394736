import Benefits from "../components/Benefits";
import ChatBotWrapper from "../components/ChatBotWrapper";
import FlexInfo from "../components/FlexInfo";
import Hero from "../components/Hero";
// import Ideas from "../components/Ideas";
import Schemas from "../components/Schemas";

function Home(props) {
  return (
    <>
      <ChatBotWrapper />
      <Hero />
      <FlexInfo />
      <Benefits />
      <Schemas />
      {/* <Ideas /> */}
    </>
  );
}

export default Home;
