import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../contexts/AppContext";

function ClearHead(props) {
  const { headerHeight } = useContext(AppContext);
  const divRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (divRef.current && !pathname.includes("#")) {
      divRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [divRef, pathname]);

  return <div ref={divRef} style={{ height: headerHeight }}></div>;
}

export default ClearHead;
