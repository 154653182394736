import React, { useContext, useState } from "react";
import Avater from "../components/Avater";
import ClearHead from "../components/ClearHead";
import { Form, FormInput, Submit } from "../form";
import * as Yup from "yup";
import CheckBox from "../components/CheckBox";
import useClient from "../hooks/useClient";
import Error from "../form/Error";
import countries from "../tools/countries";
import urls from "../api/urls";
import AppContext from "../contexts/AppContext";

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required().label("Phone Number"),
  country: Yup.string().required().label("Country"),
  state: Yup.string().label("State"),
  address: Yup.string().required().label("Address"),
  age: Yup.number().required().label("Age"),
});

function UpdateProfile(props) {
  const [isMale, setIsMale] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState(null);
  const { isLoading, patch, post } = useClient();
  const { user } = useContext(AppContext);

  const handleUpdate = async (data) => {
    const res1 = await patch(urls.profile.updateAge, { age: data.age });
    const res2 = await patch(urls.profile.updateGender, {
      gender: isMale ? "Male" : isMale === false ? "Female" : "Other",
    });
    const { country, state, address, phoneNumber } = data;

    const res3 = await patch(urls.profile.updateBasic, {
      country,
      state,
      address,
      phoneNumber,
    });
    let res4 = null;

    if (!user.tazapayId) {
      res4 = await post(urls.profile.createPaymentProfile, {
        country,
        state,
        address,
        phoneNumber,
      });
    }
    if (res1.error || res2.error || res3.error || res4.error)
      window.location = "/profile/update";
  };
  return (
    <>
      <ClearHead />
      <div className="container manrope containerh auth_container">
        <div className="form register">
          <Avater />
          <h2>Complete Profile</h2>
          <Form
            onSubmit={handleUpdate}
            validationSchema={validationSchema}
            initialValues={{
              country: "",
              state: "",
              address: "",
              phoneNumber: "",
              age: "",
            }}
          >
            <FormInput
              label="Phone Number"
              placeholder="Phone Number"
              name="phoneNumber"
            />
            <div className="flex">
              <CheckBox
                checked={isMale === true}
                onChange={() => setIsMale(true)}
                className="fcheck"
                label="Male"
              />
              <CheckBox
                checked={isMale === false}
                onChange={() => setIsMale(false)}
                className="fcheck"
                label="Female"
              />
            </div>

            <div className="inputs">
              <FormInput
                name="country"
                label="Country"
                placeholder="Select country"
                select
                options={countries.map((c) => ({
                  value: c.name,
                  label: c.name,
                }))}
              />
              <FormInput name="state" label="State" placeholder="State" />
            </div>
            <div className="inputs">
              <FormInput name="address" placeholder="Address" label="Address" />
              <FormInput
                name="age"
                type="number"
                placeholder="Age"
                label="Age"
              />
            </div>
            <Error error={errorMessage} touched />
            <Submit
              loading={isLoading}
              page="/"
              className="auth_btn"
              title="Save"
            />
          </Form>
        </div>
      </div>
    </>
  );
}

export default UpdateProfile;
