function Avater(props) {
  return (
    <div className="avater">
      <svg
        width="125"
        height="124"
        viewBox="0 0 125 124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="62.5" cy="62" r="62" fill="#E33861" />
        <circle cx="62.5002" cy="62" r="22.6467" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.259 112.655C88.1544 119.801 75.8174 124 62.4997 124C49.4059 124 37.2601 119.941 27.252 113.013C34.7812 101.309 47.9213 93.5569 62.8719 93.5569C77.6696 93.5569 90.6935 101.151 98.259 112.655Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default Avater;
