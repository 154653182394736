import React, { useCallback, useEffect } from "react";
import "../css/modal.css";

function Modal({ children, setDisplayModal }) {
  const setFalse = useCallback(() => setDisplayModal(false), [setDisplayModal]);

  useEffect(() => {
    const dismissModal = (e) => {
      const modal = document.getElementById("modal-component");
      if (e.target === modal) {
        setFalse();
      }
    };
    window.addEventListener("click", (e) => dismissModal(e));

    return window.removeEventListener("click", (e) => dismissModal(e));
  }, [setFalse]);

  return (
    <div id="modal-component" className="modalComponent">
      <div id="modalContent" className="modalContent">
        <div className="close" onClick={setFalse}>
          <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="21" cy="21" r="21" fill="#E5E5E5" />
            <path
              d="M14.293 28.7093L21.5021 21.5001M28.7112 14.291L21.5007 21.5001M21.5007 21.5001L14.293 14.291M21.5021 21.5001L28.7112 28.7093"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <>{children}</>
      </div>
    </div>
  );
}

export default Modal;
