function Title({ subtitle = "csPILLAI", title, className = "" }) {
  return (
    <div
      className={`title manrope ${className}`}
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <span style={{ color: "#E33861", fontWeight: "900", fontSize: "0.6em" }}>
        {subtitle}
      </span>
      <h2 style={{ fontWeight: "800" }}>{title}</h2>
    </div>
  );
}

export default Title;
