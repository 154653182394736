import React from "react";
import { AiFillMessage } from "react-icons/ai";

function ChatBotWrapper() {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=918850762306"
      style={{
        position: "fixed",
        bottom: "5%",
        right: "5%",
      }}
    >
      <AiFillMessage
        style={{
          backgroundColor: "#e33861",
          borderRadius: "50%",
          color: "#ffffff",
          fontSize: "1.875rem",
          lineHeight: "2.25rem",
          width: "50px",
          height: "50px",
          padding: "10px",
        }}
      />
    </a>
  );
}

export default ChatBotWrapper;
